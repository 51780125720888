

function Nav() {

    

    return(
        <>
            <div className="flex items-center justify-start h-16 ml-2">
                <img src="assets/nXTUP.svg" alt="Logo" className="pt-4 pl-2 scale-75"/>
            </div>
        </>
    )
}

export default Nav;